/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PC } from "components/Common/Responsive";
import { motion } from "framer-motion";
import plusImage from "static/mallpie/plus.png";
import list1Image from "static/mallpieBiz/6_list1.png";
import list2Image from "static/mallpieBiz/6_list2.png";
import list3Image from "static/mallpieBiz/6_list3.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section6 = () => {
  const section6Style = css`
    padding-bottom: 90px;
    .label {
      display: none;
    }
    .box {
      display: flex;
      gap: 30px;
      justify-content: center;
      .list {
        border-radius: 40px;
        background: rgba(79, 128, 255, 0.1);
        width: 1140px;
        height: 555px;
        padding: 48px 46px;
        position: relative;
        &:first-of-type {
          .img {
            width: 270px;
            right: 20px;
            bottom: 10px;
          }
        }
        &:nth-of-type(2) {
          .img {
            width: 460px;
            right: -3px;
            bottom: 10px;
          }
        }
        &.big {
          width: 1140px;
          height: 525px;
          margin-bottom: 30px;
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(79, 128, 255, 0.1) 100%
          );
          .text {
            left: 80px;
            transform: translateX(0);
            &.top {
              top: 80px;
            }
          }
          .img {
            width: 450px;
            position: absolute;
            right: 60px;
            bottom: 0;
            img {
              width: 100%;
              float: left;
            }
          }
        }
        .img {
          position: absolute;
          img {
            width: 100%;
          }
        }
        .text {
          &.top {
            bottom: auto;
            top: 50px;
          }
          .title {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
          }
          .desc {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 10px;
            color: #464646;
          }
        }
      }
    }
    ${tabandmo} {
      display: flex;
      align-items: center;
      flex-direction: column;
      .label {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 36px;
        color: #7551c0;
        background: #f2f1ff;
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .box {
        flex-direction: column;
        gap: 44px;
        .list:first-of-type {
          .img {
            width: 147px;
            right: auto;
            bottom: auto;
          }
        }
        .list:nth-of-type(2) {
          .img {
            width: 225px;
            right: auto;
            bottom: auto;
          }
        }
        .list {
          background: #fff;
          padding: 0 20px;
          background-color: #fff;
          width: 100%;
          flex-direction: column-reverse;
          height: auto;
          display: flex;
          align-items: center;
          .text {
            width: 100%;
            margin-top: 20px;
            .title {
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
            }
            .desc {
              font-size: 14.37px;
              font-style: normal;
              font-weight: 400;
              line-height: 24.634px;
            }
          }
          .img {
            position: relative;
            display: flex;
            width: 100%;
            right: auto;
            bottom: auto;
          }
        }
        .list.big {
          width: auto;
          height: auto;
          background: #fff;
          margin-bottom: 40px;
          .img {
            position: relative;
            display: flex;
            width: 249px;
            right: auto;
            bottom: auto;
          }
        }
      }
    }
  `;

  return (
    <>
      <div css={section6Style}>
        <div className="label">자사 브랜드 쇼핑몰</div>
        <div css={container}>
          <div className="box">
            <motion.div
              className="list big"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text top">
                <div className="title">임직원이 만족하는 상품과 가격 혜택</div>
                <div className="desc">
                  온라인 최저가, 무료배송, 10만여 개의 상품 구성 등<br />
                  몰파이 커머스 혜택을 우리 기업 임직원에게도 <br />
                  동일하게 제공할 수 있습니다.
                </div>
              </div>
              <div className="img">
                <img src={list1Image} />
              </div>
            </motion.div>
          </div>
          <div className="box half">
            <motion.div
              className="list"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="title">까다로운 조건 없이, 즉시 개설</div>
                <div className="desc">
                  기업 규모나 예산 등의 조건 상관없이
                  <br /> 가입 즉시 우리 기업 복지몰을 이용할 수 있습니다.
                </div>
              </div>
              <div className="img">
                <img src={list2Image} />
              </div>
            </motion.div>
            <motion.div
              className="list"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text top">
                <div className="title">쉽고 편리한 이용 편의성</div>
                <div className="desc">
                  임직원 관리, 한눈에 보는 대시보드 등<br />
                  교육이 필요 없는 직관적인 관리자 페이지를 제공합니다.
                </div>
              </div>
              <div className="img">
                <img src={list3Image} />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Section6;
