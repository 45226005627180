/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PageTitle from "components/Common/PageTitle";
import { Mobile, PC } from "components/Common/Responsive";
import { useMediaQuery } from "react-responsive";
import TitleImage from "static/mallpieBiz/title.png";
import logoImage from "static/mallpieBiz/m_logo.png";
import { container } from "styles";
import { tabandmo } from "styles/media";
import { motion } from "framer-motion";

const Section1 = () => {
  const section1Style = css`
    padding: 120px 0;
    .img {
      width: 778px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    ${tabandmo} {
      padding: 0px 0 50px 0;
      .logo {
        width: 170px;
        margin: 0 auto;
        margin-top: 18px;
        img {
          width: 100%;
        }
      }
      .img {
        width: 100%;
        padding: 0 24px;
        margin-top: 52px;
      }
      .desc {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
        margin-top: 40px;
      }
    }
  `;
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <>
      <PC>
        <PageTitle
          titleText="몰파이 BIZ"
          subTitleText={`커머스로 만드는 새로운 기회의 시작`}
          contentsTitleText={
            isMobile
              ? `커머스로 우리 기업과\n비즈니스를\n성장시키는 새로운 방법`
              : `커머스로 우리 기업과 비즈니스를\n성장시키는 새로운 방법`
          }
          contentsSubTitleText={`몰파이는 국내 유일의 상품 결합형 쇼핑 플랫폼으로서,\n국내 유수의 기업들이 몰파이와 함께\n비즈니스 성장을 이뤄내고 있습니다.`}
          fill="#7C70E9"
        />
      </PC>

      <div css={section1Style}>
        <Mobile>
          <PageTitle
            titleText="몰파이 BIZ"
            subTitleText={`커머스로 만드는 새로운 기회의 시작`}
            contentsTitleText={
              isMobile
                ? `커머스로 우리 기업과\n비즈니스를\n성장시키는 새로운 방법`
                : `커머스로 우리 기업과 비즈니스를\n성장시키는 새로운 방법`
            }
            fill="#7C70E9"
          />
          <div className="logo">
            <img src={logoImage} alt="logo" />
          </div>
          <div className="desc">
            몰파이는 국내 유일의 상품 결합형 쇼핑 플랫폼으로서,
            <br />
            국내 유수의 기업들이 몰파이와 함께 <br />
            비즈니스 성장을 이뤄내고 있습니다.
          </div>
        </Mobile>
        <div css={container}>
          <motion.div
            className="img"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <img src={TitleImage} alt="TitleImage" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Section1;
