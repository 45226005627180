/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import XBar from "components/Intraction/xBar";
import { motion } from "framer-motion";
import React from "react";
import { container } from "styles";
import icon1 from "static/employment/icon1.png";
import icon2 from "static/employment/icon2.png";
import icon3 from "static/employment/icon3.png";
import icon4 from "static/employment/icon4.png";
import icon5 from "static/employment/icon5.png";
import icon6 from "static/employment/icon6.png";
import icon7 from "static/employment/icon7.png";
import icon8 from "static/employment/icon8.png";
import icon9 from "static/employment/icon9.png";
import { Mobile, PC } from "components/Common/Responsive";

const Section4 = () => {
  const section4Style = css`
    padding: 160px 0;
    > div > .title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 260px;
      .text {
        font-size: 52px;
        text-align: center;
        font-weight: 700;
        line-height: 68px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
      .left {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    > div > .list {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 33.33333333%;
        flex-shrink: 0;
        margin-top: 158px;
        img {
          width: 55px;
        }
        .title {
          margin-top: 24px;
          font-weight: 700;
          font-size: 24px;
        }
        .text {
          color: #666;
          font-size: 16px;
          font-weight: 300;
          margin-top: 10px;
        }
      }
    }
  `;

  const section4MoStyle = css`
    padding: 60px 0;
    > div > .title {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .text {
        font-size: 32px;
        text-align: center;
        font-weight: 700;
        line-height: 48px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
      .left {
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    > div > .list {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        flex-shrink: 0;
        margin-top: 30px;
        img {
          width: 45px;
        }
        .title {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
        }
        .text {
          margin-top: 4px;
          color: #666;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section4Style}>
          <motion.div
            css={container}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <motion.div
              className="title"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="right">
                <XBar width={195} height={130} type="left" />
                <br />
                <motion.svg width={195} height={130}>
                  <motion.circle cx={65} cy={65} r={65} fill={"#4F80FF"} />
                  <motion.circle cx={195} cy={65} r={65} fill={"#4F80FF"} />
                </motion.svg>
              </div>
              <div className="text">
                업무 몰입을 위한 <br />
                지니웍스의 복지와 혜택
              </div>
              <div className="left">
                <motion.svg width={162} height={162}>
                  <motion.circle cx={0} cy={162} r={162} fill={"#7C70E9"} />
                </motion.svg>
              </div>
            </motion.div>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={icon1} alt="icon" />
                <div className="title">간식 및 음료</div>
                <div className="text">무제한 간식과 음료 제공</div>
              </li>
              <li>
                <img src={icon2} alt="icon" />
                <div className="title">생일 연차 제공</div>
                <div className="text">생일 기념 유급 연차</div>
              </li>
              <li>
                <img src={icon3} alt="icon" />
                <div className="title">휴식 공간 운영</div>
                <div className="text">피로를 풀어줄 안마의자 제공</div>
              </li>
              <li>
                <img src={icon4} alt="icon" />
                <div className="title">자기개발 비용 지원</div>
                <div className="text">교육, 세미나, 도서 지원</div>
              </li>
              <li>
                <img src={icon5} alt="icon" />
                <div className="title">경조사 지원</div>
                <div className="text">경조금, 경조휴가 지원</div>
              </li>
              <li>
                <img src={icon6} alt="icon" />
                <div className="title">장기 근속 포상</div>
                <div className="text">3년, 5년 근속 포상금</div>
              </li>
              <li>
                <img src={icon7} alt="icon" />
                <div className="title">직원 추천 인센티브</div>
                <div className="text">직원 추천 입사 시 인센티브 지급</div>
              </li>
              <li>
                <img src={icon8} alt="icon" />
                <div className="title">청년내일채움공제</div>
                <div className="text">요건 충족 직원 가입 지원</div>
              </li>
              <li>
                <img src={icon9} alt="icon" />
                <div className="title">근로자 휴가지원 사업</div>
                <div className="text">리프레쉬를 위한 휴가 장려</div>
              </li>
            </motion.ul>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section4MoStyle}>
          <div css={container}>
            <div className="title">
              <div className="text">
                업무 몰입을 위한 <br />
                지니웍스의 복지와 혜택
              </div>
            </div>

            <ul className="list">
              <li>
                <img src={icon1} alt="icon" />
                <div className="title">간식 및 음료</div>
                <div className="text">무제한 간식과 음료 제공</div>
              </li>
              <li>
                <img src={icon2} alt="icon" />
                <div className="title">생일 연차 제공</div>
                <div className="text">생일 기념 유급 연차</div>
              </li>
              <li>
                <img src={icon3} alt="icon" />
                <div className="title">휴식 공간 운영</div>
                <div className="text">피로를 풀어줄 안마의자 제공</div>
              </li>
              <li>
                <img src={icon4} alt="icon" />
                <div className="title">자기개발 비용 지원</div>
                <div className="text">교육, 세미나, 도서 지원</div>
              </li>
              <li>
                <img src={icon5} alt="icon" />
                <div className="title">경조사 지원</div>
                <div className="text">경조금, 경조휴가 지원</div>
              </li>
              <li>
                <img src={icon6} alt="icon" />
                <div className="title">장기 근속 포상</div>
                <div className="text">3년, 5년 근속 포상금</div>
              </li>
              <li>
                <img src={icon7} alt="icon" />
                <div className="title">직원 추천 인센티브</div>
                <div className="text">직원 추천 입사 시 인센티브 지급</div>
              </li>
              <li>
                <img src={icon8} alt="icon" />
                <div className="title">청년내일체움공제</div>
                <div className="text">요건 충족 직원 가입 지원</div>
              </li>
              <li>
                <img src={icon9} alt="icon" />
                <div className="title">근로자 휴가지원 사업</div>
                <div className="text">리프레쉬를 위한 휴가 장려</div>
              </li>
            </ul>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section4;
