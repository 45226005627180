/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PC } from "components/Common/Responsive";
import Title from "components/Common/Title";
import { motion } from "framer-motion";
import list1Image from "static/travel/1_list1.png";
import list2Image from "static/travel/1_list2.png";
import list3Image from "static/travel/1_list3.png";
import list4Image from "static/travel/1_list4.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section3 = () => {
  const section3Style = css`
    padding: 140px 0;
    ul {
      display: flex;
      flex-direction: column;
      gap: 120px;
      margin-top: 140px;
      li {
        .box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 80px;
          .text {
            .sub {
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
              color: #4f80ff;
            }
            .title {
              font-size: 32px;
              font-style: normal;
              font-weight: 800;
              line-height: 48px;
              margin-top: 10px;
            }
            .desc {
              margin-top: 20px;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 30px;
              opacity: 0.6;
            }
          }
          .img {
            width: 432px;
            img {
              width: 100%;
            }
          }
        }
      }
      li:nth-of-type(odd) {
        .box {
          flex-direction: row-reverse;
        }
      }
    }
    ${tabandmo} {
      padding: 60px 0;
      ul {
        display: flex;
        margin-top: 70px;
        flex-direction: column;
        gap: 80px;
        li {
          .box {
            display: flex;
            flex-direction: column-reverse;
            align-items: start;
            padding: 0 20px;
            .text {
              margin-top: 14px;
              .sub {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                color: #4f80ff;
              }
              .title {
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
              }
              .desc {
                margin-top: 15px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                opacity: 0.6;
              }
            }
            .img {
              width: 100%;
              margin: 0 auto;
            }
          }
        }
        li:nth-of-type(odd) {
          .box {
            flex-direction: column-reverse;
          }
        }
      }
    }
  `;

  return (
    <div css={section3Style}>
      <motion.div
        css={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
      >
        <Title title={"제공 서비스 둘러보기"} />
        <ul className="list">
          <li>
            <motion.div
              className="box"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="sub">누구나 간단히 만드는 나만의 홈페이지</div>
                <div className="title">PC/모바일 반응형 홈페이지</div>
                <div className="desc">
                  지니웍스 솔루션으로 만드는 홈페이지는 전문적인 지식이나{" "}
                  <PC>
                    <br />
                  </PC>
                  기술이 없어도 누구나 간단히 구성하고 디자인할 수 있습니다.{" "}
                  <PC>
                    <br />
                  </PC>
                  원하는 목적에 맞게 손쉽게 홈페이지 디자인을 변경할 수 있어{" "}
                  <PC>
                    <br />
                  </PC>
                  담고 싶은 여행사의 이야기를 효과적으로 전할 수 있습니다.
                </div>
              </div>
              <div className="img">
                <img src={list1Image} alt="list" title="list" />
              </div>
            </motion.div>
          </li>
          <li>
            <motion.div
              className="box"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="sub">지니웍스 ERP 하나면 여행사 업무 끝 !</div>
                <div className="title">여행 통합관리 ERP 시스템</div>
                <div className="desc">
                  대형 여행사부터 중소형 여행사까지 효율적이고{" "}
                  <PC>
                    <br />
                  </PC>
                  생산적인 업무가 가능한 관리 시스템을 경험하실 수 있습니다.{" "}
                  <PC>
                    <br />
                  </PC>
                  항공 실시간 발권, 국내 및 전세계 호텔 예약, 패키지 여행,{" "}
                  <PC>
                    <br />
                  </PC>
                  자유여행, 현지투어, 골프 투어 등 지니웍스 ERP 시스템으로{" "}
                  <PC>
                    <br />
                  </PC>
                  한 번에 관련된 모든 업무를 진행하고 해결할 수 있습니다.
                </div>
              </div>
              <div className="img">
                <img src={list2Image} alt="list" title="list" />
              </div>
            </motion.div>
          </li>
          <li>
            <motion.div
              className="box"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="sub">메이저 플랫폼 통합 연동</div>
                <div className="title">제휴 채널 연동 시스템</div>
                <div className="desc">
                  지니웍스 ERP에 업로드한 상품은{" "}
                  <PC>
                    <br />
                  </PC>
                  네이버, U+콕, 카카오, 야놀자, 인터파크, 쿠팡, 여기어때, 11번가
                  등{" "}
                  <PC>
                    <br />
                  </PC>
                  다양한 플랫폼과 연동됩니다.{" "}
                  <PC>
                    <br />
                  </PC>
                  오픈마켓마다 상품을 따로 업로드하는 불편함에서 벗어나,{" "}
                  <PC>
                    <br />
                  </PC>
                  여행사의 업무 생산성이 향상되는 효율적인 서비스입니다.
                </div>
              </div>
              <div className="img">
                <img src={list3Image} alt="list" title="list" />
              </div>
            </motion.div>
          </li>
          <li>
            <motion.div
              className="box"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="sub">지니웍스 ERP 하나면 여행사 업무 끝 !</div>
                <div className="title">자동 부킹톡 발송</div>
                <div className="desc">
                  모든 예약 데이터는 자동으로 고객에게 부킹톡(문자)이 발송되며{" "}
                  <PC>
                    <br />
                  </PC>
                  고객이 직접 모든 정보를 입력하고 여권 자동 인식 및 결제가{" "}
                  <PC>
                    <br />
                  </PC>
                  완료됩니다. 고객은 개인정보 유출 등의 불안을 덜고,{" "}
                  <PC>
                    <br />
                  </PC>
                  여행사는 고객 정보를 일일이 확인할 필요가 없습니다.
                </div>
              </div>
              <div className="img">
                <img src={list4Image} alt="list" title="list" />
              </div>
            </motion.div>
          </li>
        </ul>
      </motion.div>
    </div>
  );
};

export default Section3;
