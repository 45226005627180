/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import { motion } from "framer-motion";
import list1 from "static/mallpieBiz/4_list1.png";
import list2 from "static/mallpieBiz/4_list2.png";
import lgLogo from "static/mallpieBiz/lg_logo.png";
import { tabandmo } from "styles/media";

const Section4 = () => {
  const section4Style = css`
    .label {
      display: none;
    }
    .box {
      display: flex;
      gap: 30px;
      width: 1200px;
      margin: 0 auto;
      position: relative;
      flex-wrap: wrap;
      .list {
        width: calc(50% - 15px);
        border-radius: 40px;
        background: rgba(79, 128, 255, 0.1);
        display: flex;
        flex-direction: column;
        height: 585px;
        padding: 64px 50px;
        position: relative;
        .text {
          .title {
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            letter-spacing: -0.3px;
          }
          .desc {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.2px;
            margin-top: 12px;
            color: #464646;
          }
        }
        .img {
          width: 525px;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          img {
            width: 100%;
            margin-right: -10px;
          }
        }
        button {
          border-radius: 80px;
          padding: 8px 15px 8px 25px;
          border: 0;
          background: #4f80ff;
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin-top: 43px;
        }
      }
      .list:nth-of-type(2) {
        .img {
          width: 363px;
          bottom: -24px;
        }
      }
      .lg {
        padding: 30px;
        background: var(--white, #fff);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
        width: 100%;
        border-radius: 12px;
        margin-top: 60px;
        .mark {
          font-size: 34px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          color: #69758c;
        }
        .text {
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          color: #69758c;
          span {
            font-weight: bold;
          }
        }
        .logo {
          img {
            width: 114px;
          }
          text-align: right;
          margin-top: 40px;
        }
      }
    }
    ${tabandmo} {
      display: flex;
      align-items: center;
      flex-direction: column;
      .label {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 36px;
        color: #7551c0;
        background: #f2f1ff;
        margin-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
      .box {
        flex-direction: column;
        width: 100%;
        gap: 30px;
        padding-bottom: 54px;
        .list {
          width: 100%;
          height: auto;
          background-color: #fff;
          flex-direction: column-reverse;
          padding: 0px 30px;
          .img {
            position: relative;
            bottom: auto;
            left: auto;
            transform: translate(0);
            width: 100%;
          }
          .text {
            margin-top: 20px;
            .title {
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
            }
            .desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              opacity: 0.6;
            }
          }
        }
        .list:nth-of-type(2) {
          .img {
            margin: 0 auto;
            width: 246px;
            bottom: auto;
          }
        }
      }
    }
  `;

  return (
    <div css={section4Style}>
      <motion.div
        className="label"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        자사 브랜드 쇼핑몰
      </motion.div>
      <div className="box">
        <motion.div
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <div className="text">
            <div className="title">각 기업별 커스터마이징</div>
            <div className="desc">
              기업 아이덴티티에 맞춘 컬러, 이미지, 아이콘 등으로
              <br />
              브랜드에 최적화된 디자인과 기능을 갖춘
              <br />
              맞춤형 쇼핑몰을 제공합니다.
            </div>
          </div>
          <div className="img">
            <img src={list1} alt="mainImg" />
          </div>
        </motion.div>
        <motion.div
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <div className="text">
            <div className="title">새로운 수익 모델</div>
            <div className="desc">
              몰파이 쇼핑몰에서 판매가 이루어지면{" "}
              <Mobile>
                <br />
              </Mobile>
              상품 판매 수수료에 따라
              <PC>
                <br />
              </PC>
              수익이 정산됩니다.{" "}
              <Mobile>
                <br />
              </Mobile>
              추가 수익과 고객 만족도 모두를 기대할 수 있습니다.
            </div>
          </div>
          <div className="img">
            <img src={list2} alt="mainImg" />
          </div>
        </motion.div>
        <PC>
          <motion.div
            className="lg"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="mark">“</div>
            <div className="text">
              몰파이 쇼핑에서 <span>소싱된 상품과 문자 메시지 마케팅</span>으로
              정기적인 쇼핑 수수료 매출 뿐 아니라, <br />
              소비자 조사에서 높은 만족도의 결과를 얻을 수 있었습니다.
            </div>
            <div className="logo">
              <img src={lgLogo} alt="" />
            </div>
          </motion.div>
        </PC>
      </div>
    </div>
  );
};
export default Section4;
