/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import main1Image from "static/travel/main1.png";
import { container } from "styles";
import { tabandmo } from "styles/media";
import Title from "components/Common/Title";
import { Mobile } from "components/Common/Responsive";
import { motion } from "framer-motion";

const Section4 = () => {
  const section4Style = css`
    padding: 140px 0px 180px 0px;
    background-color: rgba(164, 189, 255, 0.2);
    .img {
      display: flex;
      width: 100%;
      margin: 80px auto 0 auto;
      img {
        width: 100%;
      }
    }
    .text {
      margin-top: 120px;
      text-align: center;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
    }
    ${tabandmo} {
      padding: 58px 0px 85px 0px;
      .img {
        display: flex;
        margin-top: 115px;
        margin: 53px auto 0 auto;
        img {
          width: 100%;
        }
      }
      .text {
        margin-top: 60px;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
    }
  `;

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <div css={section4Style}>
      <motion.div
        css={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
      >
        <Title title={"주요 도입 사례"} />
        <motion.div
          className="img"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <img src={main1Image} alt="list1" />
        </motion.div>
        <motion.div
          className="text"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          지금 도입하시고{" "}
          <Mobile>
            <br />
          </Mobile>
          고객 접점 강화와 업무 효율화로{" "}
          <Mobile>
            <br />
          </Mobile>
          매출을 견인해 보세요!
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Section4;
