/** @jsxImportSource @emotion/react */
import SectionTitle from "components/Common/SectionTitle";
import { css } from "@emotion/react";
import commonArrowRight from "static/common/arrow_right.svg";
import logo from "static/mallpieBiz/logo.png";
import { tabandmo } from "styles/media";
import { useMediaQuery } from "react-responsive";
import { Mobile, PC } from "components/Common/Responsive";
import { motion } from "framer-motion";

const Section8 = () => {
  const Section8Style = css`
    max-width: 100%;
    width: 100%;
    padding: 130px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: #fff;
    .logo {
      display: none;
    }
    .title {
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
    }
    button {
      margin: 0 auto;
      border-radius: 80px;
      padding: 12px 34px 12px 49px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 250px;
      margin-top: 80px;
      cursor: pointer;
    }
    @keyframes dvufTn {
      0% {
        transform: translate(0px);
      }

      100% {
        transform: translate(-50%);
      }
    }
    .imgs {
      width: max-content;
      gap: 24px;
      align-items: flex-end;
      animation: 30s linear 0s infinite normal none running dvufTn;
      display: flex;
      margin-top: 96px;

      li {
        img {
          width: 100%;
        }
      }
    }
    .more {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      padding: 12px 20px;
      color: #6079ff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      gap: 8px;
      cursor: pointer;
    }

    ${tabandmo} {
      padding: 90px 0px;

      .logo {
        width: 108px;
        margin: 16px auto 0;
        img {
          width: 100%;
        }
      }

      .imgs {
        gap: 12px;
        margin-top: 48px;
      }
      .more {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .title {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
      button {
        border-radius: 80px;
        width: 200px;
        justify-content: center;
        padding: 6px 20px 6px 34px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 26px;
      }
    }
  `;

  return (
    <div css={Section8Style}>
      <motion.div
        className="title"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        커머스 도입으로 만드는
        <br />
        새로운 성장 동력의 시작<PC>, 몰파이</PC>
      </motion.div>
      <motion.div
        className="logo"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        <img src={logo} alt="" />
      </motion.div>
      <motion.button
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
        onClick={() => window.open("https://mallpie.biz/inquiry")}
      >
        도입 문의
        <img src={commonArrowRight} alt="commonArrowRight" />
      </motion.button>
    </div>
  );
};

export default Section8;
