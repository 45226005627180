/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import listImage from "static/mallpie/list1.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section2 = () => {
  const section2Style = css`
    padding: 40px 0;
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 80px;
      .text {
        .title {
          font-size: 32px;
          font-style: normal;
          font-weight: 800;
          line-height: 48px;
        }
        .desc {
          margin-top: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          opacity: 0.6;
        }
      }
      .img {
        width: 432px;
        img {
          width: 100%;
        }
      }
    }
    ${tabandmo} {
      padding: 30px 0;
      .box {
        display: flex;
        flex-direction: column-reverse;
        align-items: start;
        padding: 0 20px;
        .text {
          margin-top: 14px;
          .title {
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          .desc {
            margin-top: 15px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            opacity: 0.6;
          }
        }
        .img {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  `;

  return (
    <div css={section2Style}>
      <div css={container}>
        <motion.div
          className="box"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <div className="text">
            <div className="title">
              10만여 개의 상품 연동으로
              <br />
              상품 걱정없이, 쇼핑몰 시작
            </div>
            <div className="desc">
              원하는 상품을 내 쇼핑몰에 연동하여 누구나 쉽고
              <br />
              빠르게 온라인 비즈니스를 시작하세요!
              <br />
              식품, 패션, 생활, 가전 등 종합 카테고리를 커버하는 <br />
              상품을 경쟁력 있는 가격으로 판매 할 수 있습니다.
            </div>
          </div>
          <div className="img">
            <img src={listImage} alt="list" title="list" />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Section2;
