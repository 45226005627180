/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import logo from "static/common/logo.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { tabandmo } from "styles/media";

const Footer = () => {
  const navigate = useNavigate();
  const [select, setSelect] = useState(false);
  const footerStyle = css`
    padding: 49px 20px;
    background: #4f80ff;
    font-size: 14px;
    line-height: 30px;
    .top {
      img {
        width: 140px;
      }
    }
    .bottom {
      font-size: 12px;
      line-height: 22px;
      color: #fff;
      margin-top: 32px;
    }
    .menu {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      li {
        padding: 0 40px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .select {
      padding: 14px 12px;
      padding-top: ${select ? 0 : "14px"};
      cursor: pointer;
      border: 1px solid #fff;
      color: #fff;
      width: 260px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #4f80ff;
      li {
        padding: 8px 0px;
        cursor: pointer;
      }
      ::after {
        content: "Family Site";
        display: block;
      }
      ::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-bottom: 8px solid ${select ? "#fff" : "transparent"};
        border-top: 8px solid ${select ? "transparent" : "#fff"};
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        right: 12px;
        bottom: ${select ? "24px" : "18px"};
      }
    }
    ${tabandmo} {
      .menu {
        display: none;
      }
      .bottom {
        padding-bottom: 80px;
      }
      .select {
        position: absolute;
        z-index: 10;
        margin-top: 20px;
        bottom: 0;
        left: 0;
      }
    }
  `;
  return (
    <div css={footerStyle}>
      <div css={container}>
        <div>
          <div className="top">
            <img src={logo} alt="logo" />
          </div>
          <div className="bottom">
            대표 : 이 앙 | 사업자 등록번호 : 551-88-00073
            <br />
            전화 : 1800-1011 | 문의 : support@genieworks.net <br />
            주소 : 강남구 논현로 653, 2층(에이엔에이빌딩)
            <br />
            Copyright © 2025 Genieworks.Inc All Right Reserved.
          </div>
          <ul className="menu">
            <li onClick={() => navigate("/companyinfo")}>회사소개</li>
            <li onClick={() => navigate("/business/mallpiebiz")}>비즈니스</li>
            <li onClick={() => navigate("/news/list")}>뉴스</li>
            <li onClick={() => navigate("/employment")}>채용</li>
            <li onClick={() => navigate("/inquiry")}>문의</li>
          </ul>
          <ul className="select" onClick={() => setSelect(!select)}>
            {select && (
              <>
                <li
                  onClick={() =>
                    window.open("https://www.atzcare.com/main/main.az")
                  }
                >
                  오토카지
                </li>
                <li onClick={() => window.open("https://www.livingpick.com/")}>
                  리빙픽
                </li>
                <li onClick={() => window.open(" https://www.oye.co.kr/")}>
                  오예커뮤니케이션
                </li>
                <li onClick={() => window.open("https://mallpie.kr/")}>
                  몰파이
                </li>
                <li onClick={() => window.open("https://www.traview.co.kr/")}>
                  트래뷰
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
