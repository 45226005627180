/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import MainImage from "static/travel/main.png";
import List1Image from "static/travel/list1.png";
import List2Image from "static/travel/list2.png";
import List3Image from "static/travel/list3.png";
import List4Image from "static/travel/list4.png";
import { container } from "styles";
import { tabandmo } from "styles/media";
import { motion } from "framer-motion";
import { Mobile } from "components/Common/Responsive";

const Section1 = () => {
  const section1Style = css`
    padding: 100px 0px 150px 0px;
    .main {
      text-align: center;
      img {
        width: 500px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 80px;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
        gap: 20px;
        .img {
          width: 195px;
          height: 195px;
          background: #fff;
          border-radius: 50%;
          filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.08));
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .text {
          font-size: 28px;
          font-style: normal;
          font-weight: 800;
          line-height: 48px;
        }
        &:nth-of-type(1) .img img {
          width: 134px;
        }
        &:nth-of-type(2) .img img {
          width: 111px;
        }
        &:nth-of-type(3) .img img {
          width: 133px;
        }
        &:nth-of-type(4) .img img {
          width: 104px;
        }
      }
    }
    .sub {
      margin-top: 150px;
      text-align: center;
      font-size: 18px;
      color: #000;
      opacity: 0.6;
      font-weight: 500;
      line-height: 30px;
    }
    ${tabandmo} {
      padding: 66px 0px 40px 0px;
      .main {
        text-align: center;
        img {
          width: 196px;
        }
      }
      .list {
        gap: 12px;
        li {
          width: calc(50% - 6px);
          .img {
            width: 75px;
            height: 75px;
          }
          .text {
            font-size: 11px;
            font-style: normal;
            font-weight: 800;
            line-height: 48px;
          }
          &:nth-of-type(1) .img img {
            width: 49px;
          }
          &:nth-of-type(2) .img img {
            width: 41px;
          }
          &:nth-of-type(3) .img img {
            width: 49px;
          }
          &:nth-of-type(4) .img img {
            width: 38px;
          }
        }
      }
      .sub {
        margin-top: 40px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        opacity: 1;
      }
    }
  `;

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <div css={section1Style}>
      <div css={container}>
        <motion.div
          className="main"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <img src={MainImage} alt="main" />
        </motion.div>
        <motion.ul
          className="list"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <li>
            <div className="img">
              <img src={List1Image} alt="list1" />
            </div>
            <div className="text">여행사 홈페이지 제작</div>
          </li>
          <li>
            <div className="img">
              <img src={List2Image} alt="list2" />
            </div>
            <div className="text">여행사 ERP</div>
          </li>
          <li>
            <div className="img">
              <img src={List3Image} alt="list3" />
            </div>
            <div className="text">항공/호텔 실시간 예약</div>
          </li>
          <li>
            <div className="img">
              <img src={List4Image} alt="list4" />
            </div>
            <div className="text">제휴채널 연동</div>
          </li>
        </motion.ul>
        <motion.div
          className="sub"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          LG 유플러스 “유콕”, PAYCO와 같은
          <br />
          지니웍스 보유 채널 및 네이버 여행상품 등 다양한
          <Mobile>
            <br />
          </Mobile>{" "}
          제휴채널 연동 시스템을 통해 상품 판매를 극대화하고,
          <br />
          항공 실시간 예약/발권 시스템 및 전세계 호텔 API
          <Mobile>
            <br />
          </Mobile>{" "}
          연동을 통한 실시간 부킹 시스템을 지원합니다.
          <br />
          <Mobile>
            <br />
          </Mobile>
          현재 약 400개의 중소형 여행사와 협력해 디지털
          <Mobile>
            <br />
          </Mobile>
          전환을 가속화하고, 경쟁력 강화를 지원하고 있습니다.
        </motion.div>
      </div>
    </div>
  );
};

export default Section1;
