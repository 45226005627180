/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PC } from "components/Common/Responsive";
import { motion } from "framer-motion";
import commonArrowRight from "static/common/arrow_right.svg";
import main2Img from "static/mallpieBiz/main2.png";
import { container } from "styles";

const Section5 = () => {
  const section5Style = css`
    padding: 200px 0 160px 0;
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .text {
        .sub {
          color: #4f80ff;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.3px;
        }
        .title {
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.4px;
          margin-top: 15px;
        }
      }
      .img {
        width: 525px;
        img {
          width: 100%;
          margin-right: -10px;
        }
      }
      button {
        border-radius: 80px;
        padding: 8px 15px 8px 25px;
        cursor: pointer;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        margin-top: 43px;
        cursor: pointer;
      }
    }
  `;

  return (
    <PC>
      <div css={section5Style}>
        <div css={container}>
          <div className="box">
            <motion.div
              className="list"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="text">
                <div className="sub">임직원 복지몰</div>
                <div className="title">
                  몰파이 커머스의 혜택을
                  <br />
                  우리 임직원에게도 동일하게!
                  <br />
                  조건없이 바로 시작하는 복지몰
                </div>
                <button
                  onClick={() => window.open("https://mallpie.biz/welfare")}
                >
                  복지몰 더 알아보기
                  <img src={commonArrowRight} alt="commonArrowRight" />
                </button>
              </div>
              <div className="img">
                <img src={main2Img} alt="mainImg" />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </PC>
  );
};
export default Section5;
