/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import Title from "components/Common/Title";
import mListImg1 from "static/main/m_list_1.png";
import mListImg2 from "static/main/m_list_2.png";
import mListImg3 from "static/main/m_list_3.png";
import { motion } from "framer-motion";
import { useRef } from "react";
import { Mobile, PC } from "components/Common/Responsive";
import { tabandmo } from "styles/media";

const Section2 = () => {
  const section2Style = css`
    padding: 120px 0;
    .list {
      margin-top: 114px;
      li {
        display: flex;
        align-items: center;
        .img {
          width: 670px;
          img {
            width: 100%;
          }
        }
        .list_box {
          flex: 1;
          margin-left: 76px;
          .subTitle {
            color: #959595;
            font-size: 14px;
            font-weight: 600;
          }
          .title {
            font-size: 36px;
            line-height: 54px;
            font-weight: 700;
            margin-top: 12px;
          }
          .text {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            margin-top: 18px;
          }
          button {
            margin-top: 38px;
            width: 265px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            border-radius: 80px;
            background: #d4d4d4;
            font-size: 20px;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
      li + li {
        margin-top: 200px;
      }
    }
    ${tabandmo} {
      padding: 60px 0;
      .list {
        margin-top: 60px;
        li {
          flex-direction: column;
          padding: 0 20px;
          .img {
            width: 100%;
          }
          .list_box {
            margin-left: 0;
            margin-top: 24px;
            .title {
              font-size: 24px;
              line-height: 1.2;
              margin-top: 8px;
            }
            .text {
              font-size: 14px;
              margin-top: 12px;
            }
            button {
              width: 200px;
              height: 50px;
              font-size: 15px;
              margin-top: 12px;
            }
          }
        }
        li + li {
          margin-top: 50px;
        }
      }
    }
  `;

  const ref = useRef(null);

  return (
    <>
      <div css={section2Style} ref={ref}>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.1 }}
        >
          <Title
            title={
              <>
                플랫폼을 통한 혁신,
                <br />
                지니웍스 서비스로{" "}
                <Mobile>
                  <br />
                </Mobile>
                시작합니다
              </>
            }
            sub={
              <>
                몰파이, 프로모션 마케팅, 여행 플랫폼,
                <br />
                기술 기반 플랫폼으로 새로운 성장 가치를
                <Mobile>
                  <br />
                </Mobile>{" "}
                실현하고 있습니다.
              </>
            }
          />

          <div css={container}>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                },
              }}
            >
              <ul className="list">
                <li>
                  <div className="img">
                    <img src={mListImg1} alt="list" />
                  </div>
                  <div className="list_box">
                    <div className="subTitle">커머스 플랫폼</div>
                    <div className="title">몰파이 mallpie</div>
                    <div className="text">
                      몰파이에 입점된 상품을 이용해 쉽고 빠르게 쇼핑몰을{" "}
                      <PC>
                        <br />
                      </PC>
                      개설하고 고객에게 판매하는 국내 유일의 커머스 플랫폼
                      <PC>
                        <br />
                      </PC>
                      입니다. 개인, 크리에이터, 기업 누구든 원하는 쇼핑몰을{" "}
                      <PC>
                        <br />
                      </PC>
                      개설하여 수익을 만들 수 있습니다.
                    </div>
                    <button
                      onClick={() => window.open("https://mallpie.kr/store")}
                    >
                      몰파이 더 알아보기 {String.fromCharCode(62)}
                    </button>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src={mListImg2} alt="list" />
                  </div>
                  <div className="list_box">
                    <div className="subTitle">통합 마케팅 솔루션</div>
                    <div className="title">프로모션 마케팅</div>
                    <div className="text">
                      맞춤형 이벤트 제작 플랫폼 '애드머스', 게임형 리워드 플랫폼
                      <PC>
                        <br />
                      </PC>{" "}
                      '지니펀', 그리고 커머스 기반의 통합 마케팅까지.
                      지니웍스만의
                      <PC>
                        <br />
                      </PC>{" "}
                      차별화된 솔루션으로 비즈니스 성장을 이끌어갑니다.
                    </div>
                    <button
                      onClick={() => {
                        window.open(
                          "https://www.genieworks.net/business/marketing"
                        );
                      }}
                    >
                      소개 더보기 {String.fromCharCode(62)}
                    </button>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img src={mListImg3} alt="list" />
                  </div>
                  <div className="list_box">
                    <div className="subTitle">종합 여행 플랫폼</div>
                    <div className="title">스마트 투어</div>
                    <div className="text">
                      여행 산업은 이전과 달라질 수 있습니다.{" "}
                      <PC>
                        <br />
                      </PC>
                      여행사 통합 관리 솔루션부터 글로벌 파트너와 연동된{" "}
                      <PC>
                        <br />
                      </PC>
                      ERP 시스템 등 기술을 기반으로 여행업을 혁신합니다.
                    </div>
                    <button
                      onClick={() => {
                        window.open(
                          "https://www.traview.co.kr/customer/company.html"
                        );
                      }}
                    >
                      스마트 투어 더보기 {String.fromCharCode(62)}
                    </button>
                  </div>
                </li>
              </ul>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Section2;
