import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Section1 from "components/Travel/Section1";
import Section2 from "components/Travel/Section2";
import Section3 from "components/Travel/Section3";
import Section4 from "components/Travel/Section4";

import { Helmet } from "react-helmet";

const Travel = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          여행사 ERP 솔루션 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를
          바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="여행사 ERP 솔루션"
        subTitleText={
          "여행사와 MICE 유관 기업을 위한 최적의 예약·운영 관리 시스템,\n 여행사에 필요한 온라인 홈페이지를 지니웍스가 제공합니다."
        }
        contentsTitleText={`여행사 통합관리 시스템`}
        contentsSubTitleText={`지니웍스는 여행 산업의 문제점을 개선해 업무 효율을 높이고\n“여행사 DX(Digital Transformation)”를 위해\n다양한 중소 여행사들과 기업들에게 최적의 솔루션을 제공합니다.`}
        fill="#7C70E9"
      />

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
    </Layout>
  );
};

export default Travel;
