/** @jsxImportSource @emotion/react */
import SectionTitle from "components/Common/SectionTitle";
import { css } from "@emotion/react";
import commonArrowRight from "static/common/arrow_right.svg";
import shop1Img from "static/mallpie/shop1.png";
import shop3Img from "static/mallpie/shop3.png";
import shop4Img from "static/mallpie/shop4.png";
import shop5Img from "static/mallpie/shop5.png";
import shop6Img from "static/mallpie/shop6.png";
import shop7Img from "static/mallpie/shop7.png";
import { tabandmo } from "styles/media";
import { useMediaQuery } from "react-responsive";
import { Mobile } from "components/Common/Responsive";
import { motion } from "framer-motion";

const Section7 = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const shopImgArr = [
    {
      img: shop1Img,
      size: "280px",
      mSize: "140px",
    },
    {
      img: shop3Img,
      size: "280px",
      mSize: "140px",
    },
    {
      img: shop4Img,
      size: "280px",
      mSize: "140px",
    },
    {
      img: shop5Img,
      size: "280px",
      mSize: "140px",
    },
    {
      img: shop6Img,
      size: "280px",
      mSize: "140px",
    },
    {
      img: shop7Img,
      size: "280px",
      mSize: "140px",
    },
  ];

  const Section7Style = css`
    max-width: 100%;
    width: 100%;
    padding: 160px 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    background: #fff;
    .title {
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px;
      margin-top: 96px;
    }
    button {
      margin: 0 auto;
      border-radius: 80px;
      padding: 12px 34px 12px 49px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 80px;
      cursor: pointer;
    }
    @keyframes dvufTn {
      0% {
        transform: translate(0px);
      }

      100% {
        transform: translate(-50%);
      }
    }
    .imgs {
      width: max-content;
      gap: 24px;
      align-items: flex-end;
      animation: 30s linear 0s infinite normal none running dvufTn;
      display: flex;
      margin-top: 96px;

      li {
        img {
          width: 100%;
        }
      }
    }
    .more {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      padding: 12px 20px;
      color: #6079ff;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      gap: 8px;
      cursor: pointer;
    }

    ${tabandmo} {
      padding: 90px 0px;

      .imgs {
        gap: 12px;
        margin-top: 48px;
      }
      .more {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
      .title {
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
      }
      button {
        border-radius: 80px;
        padding: 6px 20px 6px 34px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 43px;
      }
    }
  `;

  return (
    <div css={Section7Style}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        <SectionTitle
          mainText={`지금, 몰파이로 만든\n다양한 마이몰을 만나보세요!`}
        />
      </motion.div>
      <motion.ul
        className="imgs"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        {shopImgArr.map((v, k) => (
          <li style={{ width: isMobile ? v.mSize : v.size }} key={`${k}-1`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
        {shopImgArr.map((v, k) => (
          <li style={{ width: isMobile ? v.mSize : v.size }} key={`${k}-2`}>
            <img src={v.img} alt="top list item" title="top list item" />
          </li>
        ))}
      </motion.ul>
      <motion.div
        className="title"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        쇼핑몰을 가장 쉽게 만들고
        <br />더 많은 고객을 만나는{" "}
        <Mobile>
          <br />
        </Mobile>
        새로운 방법, 몰파이
      </motion.div>
      <motion.button
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
        onClick={() => window.open("https://mallpie.kr")}
      >
        몰파이 더 알아보기
        <img src={commonArrowRight} alt="commonArrowRight" />
      </motion.button>
    </div>
  );
};

export default Section7;
