/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useMediaQuery } from "react-responsive";
import erpImage from "static/travel/erp.png";
import { container } from "styles";
import { tabandmo } from "styles/media";
import Title from "components/Common/Title";
import { motion } from "framer-motion";

const Section2 = () => {
  const section2Style = css`
    padding: 140px 0px 60px 0px;
    background-color: rgba(164, 189, 255, 0.2);
    .img {
      display: flex;
      margin-top: 115px;
      width: 770px;
      margin: 115px auto 0 auto;
      img {
        width: 100%;
      }
    }
    ${tabandmo} {
      padding: 58px 0px 34px 0px;
      .img {
        display: flex;
        margin-top: 115px;
        width: 308px;
        margin: 53px auto 0 auto;
        img {
          width: 100%;
        }
      }
    }
  `;

  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <div css={section2Style}>
      <motion.div
        css={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0 }}
      >
        <Title title={"지니웍스 제공 서비스"} />
        <motion.div
          className="img"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <img src={erpImage} alt="list1" />
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Section2;
