import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Section1 from "components/MallpieBiz/Section1";
import Section2 from "components/MallpieBiz/Section2";
import Section3 from "components/MallpieBiz/Section3";
import Section4 from "components/MallpieBiz/Section4";
import Section5 from "components/MallpieBiz/Section5";
import Section6 from "components/MallpieBiz/Section6";
import Section7 from "components/MallpieBiz/Section7";
import Section8 from "components/MallpieBiz/Section8";
import React from "react";
import { Helmet } from "react-helmet";

const MallpieBiz = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          몰파이 BIZ | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>

      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      {/* 
      <Section7 />
      <Section8 /> */}
    </Layout>
  );
};

export default MallpieBiz;
