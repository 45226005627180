/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import SectionTitle from "components/Common/SectionTitle";
import { motion } from "framer-motion";
import commonArrowRight from "static/common/arrow_right.svg";
import mainImg from "static/mallpieBiz/main.png";
import { container } from "styles";

const Section3 = () => {
  const section3Style = css`
    padding: 120px 0 20px 0;

    .list {
      display: flex;
      justify-content: space-between;
      margin-top: 65px;
      .text {
        margin-top: 140px;
        .sub {
          color: #4f80ff;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.3px;
        }
        .title {
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          letter-spacing: -0.4px;
          margin-top: 15px;
        }
      }
      .img {
        width: 525px;
        img {
          width: 100%;
          margin-right: -10px;
        }
      }
      button {
        border-radius: 80px;
        padding: 8px 15px 8px 25px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        margin-top: 43px;
        cursor: pointer;
      }
    }
  `;

  const section3MStyle = css`
    padding-bottom: 63px;
    .box {
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .list {
        padding: 32px 32px 64px 32px;
        border-radius: 24px;
        background: #f2f1ff;
        text-align: center;
        margin-top: 15px;
        .title {
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          line-height: 42px;
          color: #7551c0;
          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
        .desc {
          margin-top: 20px;
          color: #9f83d8;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }
      .list:nth-of-type(2) {
        background: #f1f5ff;
        .title {
          color: #4f81ff;
        }
        .desc {
          color: #4f81ff;
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section3Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <SectionTitle
              mainText={`도입 목적에 따른\n몰파이 BIZ 서비스를 확인하세요`}
            />
          </motion.div>
          <div css={container}>
            <div className="box">
              <motion.div
                className="list"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="text">
                  <div className="sub">자사 브랜드 쇼핑몰</div>
                  <div className="title">
                    우리 고객에게 <br />
                    쇼핑 서비스 제공을 고민한다면
                    <br />
                    올인원 커머스 시스템, 몰파이
                  </div>
                  <button
                    onClick={() =>
                      window.open("https://mallpie.biz/intro/company")
                    }
                  >
                    자사 쇼핑몰 더 알아보기
                    <img src={commonArrowRight} alt="commonArrowRight" />
                  </button>
                </div>
                <div className="img">
                  <img src={mainImg} alt="mainImg" />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section3MStyle}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <SectionTitle
              mainText={`도입 목적에 따른\n몰파이 BIZ\n서비스를 확인하세요`}
            />
          </motion.div>
          <div css={container}>
            <div className="box">
              <motion.div
                className="list"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="text">
                  <div className="title">
                    <span>우리 서비스 고객들을 붙잡는 방법</span>
                    <br />
                    자사 브랜드 쇼핑몰
                  </div>
                  <div className="desc">
                    몰파이가 보유한 상품으로
                    <br />
                    고객을 위한 쇼핑몰을 구성하고
                    <br />
                    신규 수익 모델을 창출합니다
                  </div>
                </div>
              </motion.div>
              <motion.div
                className="list"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="text">
                  <div className="title">
                    <span>비용은 낮추고 임직원 만족도는 높이는</span>
                    <br />
                    임직원 복지몰
                  </div>
                  <div className="desc">
                    몰파이 상품 및 혜택을
                    <br />
                    우리 기업 임직원들에게도
                    <br />
                    동일하게 제공하여 만족도를 높입니다
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};
export default Section3;
