/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import TitleImage from "static/mallpie/title.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section1 = () => {
  const section1Style = css`
    padding: 80px 0 60px 0;
    .img {
      width: 778px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    ${tabandmo} {
      padding: 50px 0;
      .img {
        width: 100%;
        padding: 0 24px;
      }
    }
  `;

  return (
    <>
      <div css={section1Style}>
        <div css={container}>
          <motion.div
            className="img"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <img src={TitleImage} alt="TitleImage" />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Section1;
