import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import titleImage from "static/esg/title.png";
import React from "react";
import Section1 from "components/Esg/Section1";
import Section2 from "components/Esg/Section2";
import Section3 from "components/Esg/Section3";
import Section4 from "components/Esg/Section4";
import Form from "components/Esg/Form";
import { Helmet } from "react-helmet";

const EsgPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          ESG | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="ESG 경영"
        subTitleText={
          <>지속 가능한 미래, 구성원과 고객에게 드리는 지니웍스의 약속입니다.</>
        }
        contentsTitleText={<>지속 가능한 발전</>}
        contentsSubTitleText={`기후 위기, 자원 부족, 생태계 파괴와 같은 문제들은 더 이상 먼 미래의\n 이야기가 아닙니다. 지구를 위협하는 문제 해결에 앞장서고 함께 미래를\n 꿈꿀 수 있는 사회를 만들어 가는 것, 신뢰받는 기업의 필수 가치입니다.`}
        fill="#7C70E9"
        textHeight={55}
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />

      <Form />
    </Layout>
  );
};

export default EsgPage;
