/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import React from "react";
import { tabandmo } from "styles/media";
interface PageTitleProps {
  titleText: React.ReactNode;
  subTitleText: React.ReactNode;
  titleImage?: any;
  contentsTitleText: React.ReactNode;
  contentsSubTitleText?: React.ReactNode;
  fill?: string;
  textHeight?: number;
}
const PageTitle = ({
  titleText,
  subTitleText,
  titleImage,
  contentsTitleText,
  contentsSubTitleText,
  fill = "#4f80ff",
  textHeight = 85,
}: PageTitleProps) => {
  const infiniteAnimation1 = keyframes`
  0% {
       transform: translateX(0%);
   }
   50% {
       transform: translateX(-100%);
   }
   50.1% {
       transform: translateX(100%);
   }
   100% {
       transform: translateX(0%);
   }
`;

  const infiniteAnimation2 = keyframes`
   0% {
       transform: translateX(0%);
   }
   100% {
       transform: translateX(-200%);
   }
`;

  const pageTitleStyle = css`
    background-color: ${fill};
    padding-top: 196px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: auto;

    .title {
      color: #fff;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 76px;
      letter-spacing: -0.7px;
      text-align: center;
    }
    .subtitle {
      padding-top: 20px;
      color: #fff;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      white-space: pre-line;
    }
    .titleImage {
      width: 1140px;
      margin-top: 60px;
      margin-bottom: 80px;
      height: 124px;
    }
    .contentsTitle {
      border-top-left-radius: 142px;
      background-color: white;
      z-index: 1;
      width: 1140px;
      padding-top: 120px;
      margin-top: ${titleImage ? "0" : "40px"};
    }
    .contentsTitle h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 130.769% */
      letter-spacing: -0.52px;
      white-space: pre-line;
    }
    .contentsTitle span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 200;
      line-height: 30px; /* 155.556% */
      letter-spacing: -0.18px;
      white-space: pre-line;
      margin: 20px 20px 0;
      display: block;
      height: ${textHeight}px;
    }
    .block {
      background-color: white;
      height: ${textHeight}px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .slide_container {
      width: 100%;
      overflow: hidden;
      .slide_wrapper {
        display: flex;
        flex-wrap: nowrap;
      }
      .slide {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        padding: 60px 0;
        &.original {
          animation: 120s linear infinite normal none running
            ${infiniteAnimation1};
          height: 328px;
        }
        &.clone {
          animation: 120s linear infinite ${infiniteAnimation2};
        }
        li {
          width: 2080px;
          img {
            width: 100%;
          }
        }
      }
    }
    ${tabandmo} {
      padding-top: 128px;
      .title {
        display: flex;
        font-size: 32px;
        line-height: 48px;
        height: 96px;
        align-items: center;
      }
      .subtitle {
        padding-top: 24px;
      }
      .contentsTitle {
        width: 100%;
        border-top-left-radius: 40px;
        padding-top: 60px;
        h1 {
          font-size: 26px;
          line-height: 38px;
        }
        span {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.16px;
          line-height: 24px;
          height: auto;
        }
      }
      .slide_container {
        .slide {
          padding: 30px 0 60px 0;
          height: auto !important;
          li {
            width: 1100px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  `;
  return (
    <div css={pageTitleStyle}>
      <div className="title">{titleText}</div>
      <div className="subtitle">{subTitleText}</div>
      {titleImage && (
        <div className="slide_container">
          <ul className="slide_wrapper">
            <div className={"slide original"}>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
            </div>
            <div className={"slide clone"}>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
              <li>
                <img src={titleImage} alt="slide1" />
              </li>
            </div>
          </ul>
        </div>
      )}
      <div className="contentsTitle">
        <h1>{contentsTitleText}</h1>
        {contentsSubTitleText && <span>{contentsSubTitleText}</span>}
      </div>
      <div className="block"></div>
    </div>
  );
};

export default PageTitle;
