import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Section1 from "components/Mallpie/Section1";
import Section2 from "components/Mallpie/Section2";
import Section3 from "components/Mallpie/Section3";
import Section4 from "components/Mallpie/Section4";
import Section5 from "components/Mallpie/Section5";
import Section6 from "components/Mallpie/Section6";
import Section7 from "components/Mallpie/Section7";
import React from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";

const Mallpie = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return (
    <Layout>
      <Helmet>
        <title>
          몰파이 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="몰파이"
        subTitleText={
          isMobile
            ? `상품 없이 누구나 만들 수 있는\n 나만의 쇼핑몰, 몰파이`
            : `상품 없이 누구나 만들 수 있는 나만의 쇼핑몰, 몰파이`
        }
        contentsTitleText={`상품과 운영이 필요 없는\n쇼핑몰의 쉬운 시작, 몰파이`}
        contentsSubTitleText={`몰파이에 입점된 상품을 클릭 한번으로 연동하고\n재고 관리, 배송 등의 운영 또한\n몰파이에서 한 번에 해결하세요.`}
        fill="#7C70E9"
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
    </Layout>
  );
};

export default Mallpie;
