/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import searchIcon from "static/news/search.svg";
import leftIcon from "static/news/left.svg";
import rightIcon from "static/news/right.svg";
import comeImage from "static/news/come.png";
import newsVideoImage1 from "static/news/video_01.jpeg";
import newsVideoImage2 from "static/news/video_02.jpeg";
import newsVideoImage3 from "static/news/video_03.jpeg";
import newsVideoImage4 from "static/news/video_04.jpeg";
import newsVideoImage5 from "static/news/video_05.jpeg";
import newsVideoImage6 from "static/news/video_06.jpeg";
import newsVideoImage7 from "static/news/video_07.jpeg";
import newsVideoImage8 from "static/news/video_08.jpeg";
import newsVideoImage9 from "static/news/video_09.jpeg";
import PageTitle from "components/Common/PageTitle";
import { tabandmo } from "styles/media";
import { useEffect, useState } from "react";
import { api } from "service/config";
import Pagination from "components/Common/Pagination";
import { Helmet } from "react-helmet";

interface NewsTypeProps {
  newsType: "image" | "video";
}
const News = ({ newsType }: NewsTypeProps) => {
  const newsStyle = css`
    .title {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      align-items: flex-end;
    }
    .total {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .total span {
      color: #4f80ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.18px;
    }
    .search {
      position: relative;
      width: 370px;
      padding: 15px 0;
      border-bottom: 1px solid #aeaeae;
    }
    .search input {
      color: var(--gray-2-aeaeae, #666);
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      border: 0;
    }
    .search input:focus {
      outline: 0;
    }
    .search i {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 28px;
      height: 28px;
      background: url(${searchIcon});
    }

    .list {
      display: flex;
      flex-wrap: wrap;
    }

    .list .item {
      width: calc(33% - 26px);
      margin-right: 44px;
      margin-top: 40px;
    }
    .list .item:nth-of-type(3n) {
      margin-right: 0;
    }
    .list .item .info {
      margin-top: 16px;
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }

    .list .item .info span {
      display: block;
      margin-top: 3px;
      color: #aeaeae;
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 185.714% */
      letter-spacing: -0.14px;
    }
    .list .item img {
      width: 100%;
      vertical-align: middle;
    }
    .pagination {
      margin-top: 105px;
      margin-bottom: 150px;
      display: flex;
      justify-content: center;
    }
    .pagination span {
      color: #aeaeae;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-right: 20px;
    }
    .pagination span.active {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }
    .left {
      background: url(${leftIcon});
      margin-right: 34px;
      width: 12px;
      height: 34px;
      background-size: 12px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .right {
      background: url(${rightIcon});
      margin-left: 14px;
      width: 12px;
      height: 34px;
      background-size: 12px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    ${tabandmo} {
      .title {
        flex-direction: column-reverse;
        .search {
          padding: 4px 0px;
          width: 100%;
          i {
            bottom: 4px;
          }
        }
        .total {
          width: 100%;
          margin-top: 36px;
          margin-top: 26px;
          padding: 10px 0;
          border-bottom: 1px solid #000;
        }
      }
      .list {
        margin-top: 24px;
        .item {
          margin: 0;
          width: 100%;
        }

        .item + .item {
          margin-top: 40px;
        }
      }
    }
  `;
  const newsVideoList = [
    {
      id: 1,
      title: <h1>[기업소개영상] 메가존클라우드 고객사 소개</h1>,
      img: newsVideoImage1,
    },
    {
      id: 2,
      title: <h1>[기업소개영상] 새로운 상생의 길, 첫걸음부터 성공까지 함께</h1>,
      img: newsVideoImage2,
    },
    {
      id: 3,
      title: (
        <h1>
          [뉴스영상] 삼성전자 손 잡았더니 매출 10배로 '껑충' / 머니투데이방송
          (뉴스)
        </h1>
      ),
      img: newsVideoImage3,
    },
    {
      id: 4,
      title: (
        <h1>
          [기업소개영상] 2020 IT·SW 우수 강소기업 청년 일자리 창출사업 소개영상
        </h1>
      ),
      img: newsVideoImage4,
    },
    {
      id: 5,
      title: <h1>찬스(Chance) 15초 온라인 홍보 영상</h1>,
      img: newsVideoImage5,
    },
    {
      id: 6,
      title: <h1>Admerce 소개 영상</h1>,
      img: newsVideoImage6,
    },
    {
      id: 7,
      title: <h1>LAZADA 프로모션 영상 - SBS콜라보 프로모션</h1>,
      img: newsVideoImage7,
    },
    {
      id: 8,
      title: <h1>삼성페이 쇼핑 프로모션 영상 - 피크닉 입점사 프로모션</h1>,
      img: newsVideoImage8,
    },
    {
      id: 9,
      title: <h1>커플에게 지금 필요한건 - 파킹온, ParkingOn</h1>,
      img: newsVideoImage9,
    },
  ];
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [video, setVideo] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (page !== undefined) {
      api
        .get("/video/videos", {
          params: {
            page,
            pageSize: 9,
          },
        })
        .then((response) => {
          setVideo(response.data.list);
          setCount(response.data.count);
        });
    }
  }, [page]);

  return (
    <Layout>
      <Helmet>
        <title>
          뉴스 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="뉴스룸"
        subTitleText="언론보도 및 최신소식을 안내드립니다."
        contentsTitleText={newsType === "image" ? "언론보도" : "동영상"}
        fill="#4F80FF"
        textHeight={55}
      />
      <Contents>
        <div css={newsStyle}>
          <div className="title">
            <div className="total">
              Total <span>{newsVideoList.length}</span>
            </div>
          </div>
          <div className="list">
            {video?.map((value, index) => (
              <div
                className="item"
                key={index}
                onClick={() => navigate("/news/video/" + value.id)}
              >
                <img
                  src={`https://i1.ytimg.com/vi/${value?.urlLink}/0.jpg`}
                  alt={`뉴스 비디오 ${value.id}`}
                />
                <div className="info">{value.title}</div>
              </div>
            ))}
          </div>
          <Pagination
            totalPosts={count}
            limit={9}
            page={page}
            setPage={setPage}
          />
        </div>
      </Contents>
    </Layout>
  );
};

export default News;
