/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import SectionTitle from "components/Common/SectionTitle";
import plusImage from "static/mallpie/plus.png";
import start1Image from "static/mallpie/start1.png";
import start2Image from "static/mallpie/start2.png";
import start3Image from "static/mallpie/start3.png";
import start1mImage from "static/mallpie/m_start1.png";
import start2mImage from "static/mallpie/m_start2.png";
import start3mImage from "static/mallpie/m_start3.png";
import { container } from "styles";
import { tabandmo } from "styles/media";
import { motion } from "framer-motion";

const Section5 = () => {
  const section5Style = css`
    padding-bottom: 90px;
    margin-top: 60px;
    .box {
      display: flex;
      gap: 42px;
      justify-content: center;
      .list {
        width: 500px;
        position: relative;
        cursor: pointer;
        &.big {
          width: 1042px;
          .text {
            left: 80px;
            transform: translateX(0);
            &.top {
              top: 80px;
            }
          }
        }
        .img {
          img {
            width: 100%;
          }
        }
        .text {
          position: absolute;
          bottom: 116px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 28px;
          white-space: nowrap;
          &.top {
            bottom: auto;
            top: 50px;
          }
          .title {
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 48px;
            letter-spacing: -1px;
          }
          .sub {
            font-size: 26px;
            font-style: normal;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: -1px;
          }
        }
        .plus {
          position: absolute;
          width: 45px;
          height: 45px;
          bottom: 70px;
          right: 50px;
          img {
            width: 100%;
          }
        }
      }
    }
  `;

  const section5MStyle = css`
    padding-bottom: 90px;
    margin-top: 40px;
    .box {
      display: flex;
      padding: 0 10px;
      justify-content: center;
      flex-direction: column;
      gap: 10px;
      .list {
        .img {
          img {
            width: 100%;
          }
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div className="box">
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <SectionTitle
              mainText={`누구나, 어떤 상황에서든\n몰파이로 쉬운 시작 `}
              subText={`개인, 크리에이터 그리고, 모임/단체까지.\n누구나 몰파이로 쇼핑몰을 만들어 상품을 구매거나, 판매할 수 있습니다.`}
              marginTop={90}
            />
          </motion.div>
        </div>
        <div css={section5Style}>
          <div css={container}>
            <motion.div
              className="box half"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div
                className="list"
                onClick={() => window.open("https://mallpie.kr/intro/personal")}
              >
                <div className="img">
                  <img src={start1Image} alt="start1" />
                </div>
                <div className="text">
                  <div className="sub">개인</div>
                  <div className="title">
                    필요한 상품 최저가로 구매하고
                    <br />
                    리워드까지 받아요
                  </div>
                </div>
                <div className="plus">
                  <img src={plusImage} alt="plus" />
                </div>
              </div>
              <div
                className="list"
                onClick={() => window.open("https://mallpie.kr/intro/class")}
              >
                <div className="img">
                  <img src={start2Image} alt="start1" />
                </div>
                <div className="text top">
                  <div className="sub">모임 단체</div>
                  <div className="title">
                    모임에 필요한 물품 구매하고
                    <br />
                    쌓인 리워드로 모임비까지 챙겨요
                  </div>
                </div>
                <div className="plus">
                  <img src={plusImage} alt="plus" />
                </div>
              </div>
            </motion.div>
            <motion.div
              className="box"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div
                className="list big"
                onClick={() => window.open("https://mallpie.kr/intro/creator")}
              >
                <div className="img">
                  <img src={start3Image} alt="start3" />
                </div>
                <div className="text top">
                  <div className="sub">크리에이터</div>
                  <div className="title">
                    마이크로 크리에이터도
                    <br />내 브랜드 쇼핑몰을 만들 수 있어요
                  </div>
                </div>
                <div className="plus">
                  <img src={plusImage} alt="plus" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </PC>
      <Mobile>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <SectionTitle
            mainText={`누구나, 어떤 상황에서든\n몰파이로 쉬운 시작 `}
            subText={`개인, 크리에이터 그리고, 모임/단체까지.\n누구나 몰파이로 쇼핑몰을 만들어\n상품을 구매거나, 판매할 수 있습니다.`}
            marginTop={90}
          />
        </motion.div>
        <div css={section5MStyle}>
          <div css={container}>
            <div className="box">
              <motion.div
                className="list"
                onClick={() => window.open("https://mallpie.kr/intro/personal")}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={start1mImage} alt="start1" />
                </div>
              </motion.div>
              <motion.div
                className="list"
                onClick={() => window.open("https://mallpie.kr/intro/class")}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={start2mImage} alt="start1" />
                </div>
              </motion.div>
              <motion.div
                className="list"
                onClick={() => window.open("https://mallpie.kr/intro/creator")}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.2 }}
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={start3mImage} alt="start1" />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section5;
