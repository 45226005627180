/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import searchIcon from "static/news/search.svg";
import leftIcon from "static/news/left.svg";
import rightIcon from "static/news/right.svg";
import comeImage from "static/news/come.png";
import PageTitle from "components/Common/PageTitle";
import { tabandmo } from "styles/media";
import { useEffect, useState } from "react";
import { api } from "service/config";
import Pagination from "components/Common/Pagination";
import moment from "moment";
import { Helmet } from "react-helmet";

interface NewsTypeProps {
  newsType: "image" | "video";
}
const News = ({ newsType }: NewsTypeProps) => {
  const newsStyle = css`
    .title {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      align-items: flex-end;
    }
    .total {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .total span {
      color: #4f80ff;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: -0.18px;
    }
    .search {
      position: relative;
      width: 370px;
      padding: 15px 0;
      border-bottom: 1px solid #aeaeae;
    }
    .search input {
      color: var(--gray-2-aeaeae, #666);
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      border: 0;
    }
    .search input:focus {
      outline: 0;
    }
    .search i {
      position: absolute;
      bottom: 14px;
      right: 0;
      width: 28px;
      height: 28px;
      background: url(${searchIcon});
    }

    .list {
      display: flex;
      flex-wrap: wrap;
    }

    .list .item {
      width: calc(33% - 26px);
      margin-right: 44px;
      margin-top: 40px;
    }
    .list .item:nth-of-type(3n) {
      margin-right: 0;
    }
    .list .item .info {
      margin-top: 16px;
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }

    .list .item .info span {
      display: block;
      margin-top: 3px;
      color: #aeaeae;
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 185.714% */
      letter-spacing: -0.14px;
    }
    .list .item .img {
      width: 100%;
      height: 260px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .pagination {
      margin-top: 105px;
      margin-bottom: 150px;
      display: flex;
      justify-content: center;
    }
    .pagination span {
      color: #aeaeae;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-right: 20px;
    }
    .pagination span.active {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }
    .left {
      background: url(${leftIcon});
      margin-right: 34px;
      width: 12px;
      height: 34px;
      background-size: 12px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }
    .right {
      background: url(${rightIcon});
      margin-left: 14px;
      width: 12px;
      height: 34px;
      background-size: 12px 18px;
      background-position: center center;
      background-repeat: no-repeat;
    }

    ${tabandmo} {
      .title {
        flex-direction: column-reverse;
        .search {
          padding: 4px 0px;
          width: 100%;
          i {
            bottom: 4px;
          }
        }
        .total {
          width: 100%;
          margin-top: 36px;
          margin-top: 26px;
          padding: 10px 0;
          border-bottom: 1px solid #000;
        }
      }
      .list {
        margin-top: 24px;
        .item {
          margin: 0;
          width: 100%;
        }

        .item + .item {
          margin-top: 40px;
        }
      }
    }
  `;

  const [page, setPage] = useState(1);
  const [news, setNews] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (page !== undefined) {
      api
        .get("/news/news", {
          params: {
            page,
            pageSize: 9,
          },
        })
        .then((response) => {
          console.log(news);
          setNews(response.data.list);
          setCount(response.data.count);
        });
    }
  }, [page]);

  const navigate = useNavigate();

  return (
    <Layout>
      <Helmet>
        <title>
          뉴스 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="뉴스룸"
        subTitleText="언론보도 및 최신소식을 안내드립니다."
        contentsTitleText={newsType === "image" ? "언론보도" : "동영상"}
        fill="#4F80FF"
        textHeight={55}
      />
      <Contents>
        <div css={newsStyle}>
          <div className="title">
            <div className="total">
              Total <span>{count}</span>
            </div>
          </div>
          <div className="list">
            {news?.map((value, index) => (
              <div
                className="item"
                key={index}
                onClick={() => navigate("/news/list/" + value?.id)}
              >
                <div
                  className="img"
                  style={{ backgroundImage: `url('${value?.filePath}')` }}
                ></div>
                <div className="info">
                  {value?.title}
                  <span>{moment(value?.createAt).format("YYYY-MM-DD")}</span>
                </div>
              </div>
            ))}
          </div>
          <Pagination
            totalPosts={count}
            limit={9}
            page={page}
            setPage={setPage}
          />
        </div>
      </Contents>
    </Layout>
  );
};

export default News;
