/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import Title from "components/Common/Title";
import pt01 from "static/main/pt01.png";
import pt02 from "static/main/pt02.png";
import pt03 from "static/main/pt03.png";
import pt04 from "static/main/pt04.png";
import pt05 from "static/main/pt05.png";
import pt06 from "static/main/pt06.png";
import pt07 from "static/main/pt07.png";
import pt08 from "static/main/pt08.png";
import pt09 from "static/main/pt09.png";
import pt10 from "static/main/pt10.png";
import pt11 from "static/main/pt11.png";
import pt12 from "static/main/pt12.png";
import pt13 from "static/main/pt13.png";
import pt14 from "static/main/pt14.png";
import pt15 from "static/main/pt15.png";
import pt16 from "static/main/pt16.png";
import pt17 from "static/main/pt17.png";
import pt18 from "static/main/pt18.png";
import pt19 from "static/main/pt19.png";
import pt20 from "static/main/pt20.png";
import pt21 from "static/main/pt21.png";
import pt22 from "static/main/pt22.png";
import pt23 from "static/main/pt23.png";
import pt24 from "static/main/pt24.png";
import { motion } from "framer-motion";
import { tabandmo } from "styles/media";

const Section4 = () => {
  const section4Style = css`
    padding: 120px 0px;
    background: #4f80ff;
    .list-wrap {
      margin-top: 36px;
      .list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        li {
          flex-shrink: 0;
          width: 25%;
          padding: 4px;
        }
        img {
          width: 100%;
        }
      }
    }
    ${tabandmo} {
      padding: 75px 20px 90px 20px;
      .list-wrap {
        overflow-x: auto;
        overflow-y: hidden;
        .list {
          flex-wrap: nowrap;
          li {
            flex-shrink: 0;
            width: 30%;
          }
        }
      }
    }
  `;
  return (
    <div css={section4Style}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.6 }}
      >
        <Title
          title={
            <>
              지니웍스 비전에 공감하는
              <br />
              파트너들과 함께합니다
            </>
          }
          sub={
            <>
              지니웍스는 국내외 업계 최고의 기업들과의 제휴를
              <br /> 통해 플랫폼 우수성을 입증해 나가고 있습니다
            </>
          }
          theme="white"
        />
        <div css={container}>
          <div className="list-wrap">
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.4,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt01} alt="pt01" />
              </li>
              <li>
                <img src={pt02} alt="pt02" />
              </li>
              <li>
                <img src={pt03} alt="pt03" />
              </li>
              <li>
                <img src={pt04} alt="pt04" />
              </li>
            </motion.ul>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt05} alt="pt05" />
              </li>
              <li>
                <img src={pt06} alt="pt06" />
              </li>
              <li>
                <img src={pt07} alt="pt07" />
              </li>
              <li>
                <img src={pt08} alt="pt08" />
              </li>
            </motion.ul>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt09} alt="pt09" />
              </li>
              <li>
                <img src={pt10} alt="pt10" />
              </li>
              <li>
                <img src={pt11} alt="pt11" />
              </li>
              <li>
                <img src={pt12} alt="pt12" />
              </li>
            </motion.ul>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.7,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt13} alt="pt13" />
              </li>
              <li>
                <img src={pt14} alt="pt14" />
              </li>
              <li>
                <img src={pt15} alt="pt15" />
              </li>
              <li>
                <img src={pt16} alt="pt16" />
              </li>
            </motion.ul>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.8,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt17} alt="pt17" />
              </li>
              <li>
                <img src={pt18} alt="pt18" />
              </li>
              <li>
                <img src={pt19} alt="pt19" />
              </li>
              <li>
                <img src={pt20} alt="pt20" />
              </li>
            </motion.ul>
            <motion.ul
              className="list"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: 0.9,
                    duration: 0.3,
                  },
                },
              }}
            >
              <li>
                <img src={pt21} alt="pt21" />
              </li>
              <li>
                <img src={pt22} alt="pt22" />
              </li>
              <li>
                <img src={pt23} alt="pt23" />
              </li>
              <li>
                <img src={pt24} alt="pt24" />
              </li>
            </motion.ul>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Section4;
