/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import listImage from "static/mallpie/list2.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section3 = () => {
  const section3Style = css`
    padding: 40px 0;
    .box {
      display: flex;
      align-items: center;
      .text {
        margin-left: 60px;
        .title {
          font-size: 32px;
          font-style: normal;
          font-weight: 800;
          line-height: 48px;
        }
        .desc {
          margin-top: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px;
          opacity: 0.6;
        }
      }
      .img {
        width: 587px;
        img {
          width: 100%;
        }
      }
    }
    ${tabandmo} {
      padding: 30px 0;
      .box {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 0 20px;
        .text {
          margin-top: 14px;
          margin-left: 0px;
          .title {
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
          .desc {
            margin-top: 15px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
        .img {
          width: 100%;
          margin: 0 auto;
        }
      }
    }
  `;

  return (
    <div css={section3Style}>
      <div css={container}>
        <motion.div
          className="box"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <div className="img">
            <img src={listImage} alt="list" title="list" />
          </div>
          <div className="text">
            <div className="title">
              누구나 근사한 쇼핑몰의 <br />
              주인이 되는, 편리한 사용성
            </div>
            <div className="desc">
              클릭 몇 번으로 내 개성이 담긴 나만의 쇼핑몰을 <br />
              완성할 수 있습니다. 한 끝이 다른 커머스 기능, <br />
              편리한 사용성을 가진 인터페이스로 <br />
              나만의 브랜드를 표현하세요.
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Section3;
