/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import bg from "static/mallpieBiz/bg.png";
import mbg from "static/mallpieBiz/mbg.png";
import logo from "static/mallpieBiz/logo.png";
import commonArrowRight from "static/common/arrow_right.svg";
import { container } from "styles";
import { tabandmo } from "styles/media";
import { motion } from "framer-motion";

const Section7 = () => {
  const section7Style = css`
    background-color: #fff;
    padding: 140px 0px;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    .main {
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: -0.36px;
      span {
        color: #4f80ff;
      }
      .logo {
        width: 108px;
        margin-bottom: 20px;
        img {
          width: 100%;
        }
      }
      button {
        border-radius: 80px;
        padding: 12px 34px 12px 45px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        display: flex;
        align-items: center;
        margin-top: 54px;
        cursor: pointer;
      }
    }
    .box {
      display: flex;
      margin-top: 115px;
      gap: 40px;
      .list {
        flex: 1;
        padding: 53px 39px;
        background: #fff;
        box-shadow: 0px 3.7px 18.502px 0px rgba(0, 0, 0, 0.1);
        border-radius: 35px;
        .title {
          padding-bottom: 17px;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 42px;
          color: #464646;
          border-bottom: 1px solid #989797;
        }
        .desc {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          margin-top: 30px;
          span {
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.28px;
          }
        }
      }
    }

    ${tabandmo} {
      padding: 70px 20px;
      background-position: right bottom;
      background-color: #f2f4f7;
      background-image: url(${mbg});
      background-size: calc(100%);
      .main {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        button {
          border-radius: 80px;
          justify-content: center;
          padding: 6px 20px 6px 34px;
          border: 0;
          background: #4f80ff;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-top: 26px;
        }
      }
      .box {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
        gap: 30px;
        .list {
          padding: 0;
          border-radius: 12px;
          background: transparent;
          box-shadow: none;
          .title {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 15px;
          }
          .desc {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%;
            margin-top: 15px;
            span {
              font-size: 16px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section7Style}>
      <div css={container}>
        <motion.h2
          className="main"
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            offscreen: {
              y: 100,
              opacity: 0,
            },
            onscreen: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.3,
              },
            },
          }}
        >
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          하루 10만명이 이용 중인 <span>몰파이,</span> <br />
          커머스 전문기업 <span>지니웍스</span>가 <br />
          만들고 운영합니다.
          <button onClick={() => window.open("https://mallpie.biz/download")}>
            서비스 소개서 받기
            <img src={commonArrowRight} alt="commonArrowRight" />
          </button>
        </motion.h2>
        <div className="box">
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="title">누적 거래액</div>
            <div className="desc">
              2,800<span>억원+</span>
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="title">보유 상품 수</div>
            <div className="desc">
              100,000<span>개+</span>
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="title">상품 공급 파트너</div>
            <div className="desc">
              10,000<span>개+</span>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Section7;
