/** @jsxImportSource @emotion/react */
import SectionTitle from "components/Common/SectionTitle";
import { css } from "@emotion/react";
import commonArrowRight from "static/common/arrow_right.svg";
import icon1 from "static/mallpie/icon1.png";
import icon2 from "static/mallpie/icon2.png";
import icon3 from "static/mallpie/icon3.png";
import icon4 from "static/mallpie/icon4.png";

import { container } from "styles";
import { Mobile, PC } from "components/Common/Responsive";
import { tabandmo } from "styles/media";
import { motion } from "framer-motion";

const Section6 = () => {
  const section6Style = css`
    padding: 120px 0 90px 0;
    background: rgba(79, 128, 255, 0.1);
    button {
      margin: 0 auto;
      border-radius: 80px;
      padding: 12px 49px 12px 49px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 43px;
      cursor: pointer;
    }
    .box {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 90px;
      .list {
        display: flex;
        width: calc(50% - 12px);
        padding: 36px;
        border-radius: 32px;
        background: linear-gradient(
          180deg,
          rgba(199, 199, 199, 0.2) 0%,
          rgba(199, 199, 199, 0.07) 100%
        );
        height: 210px;
        box-sizing: border-box;
        .img {
          width: 54px;
          flex-basis: 54px;
          img {
            width: 100%;
          }
        }
        .text {
          margin-left: 24px;
          flex: 1;
          .title {
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
          }
          .desc {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            margin-top: 25px;
          }
        }
      }
    }
    ${tabandmo} {
      button {
        border-radius: 80px;
        padding: 6px 20px 6px 34px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 43px;
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 40px;
        padding: 0 20px;
        .list {
          width: 100%;
          flex-direction: column;
          height: auto;
          padding: 0;
          background: transparent;
          .text {
            margin-left: 0;
            margin-top: 10px;
            .title {
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
            }
            .desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-top: 18px;
            }
          }
        }
      }
    }
  `;

  return (
    <div css={section6Style}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        <PC>
          <SectionTitle
            mainText={`한 번의 입점으로\n더 많은 고객을 만나는 판매 채널`}
            subText={`상품 입점사라면 한 번의 입점으로 몰파이에\n등록된 다수의 판매 채널에 동시 판매가 가능합니다. `}
          />
        </PC>
        <Mobile>
          <SectionTitle
            mainText={`한 번의 입점으로 더 많은\n고객을 만나는 판매 채널`}
            subText={`상품 입점사라면 한 번의 입점으로 몰파이에\n등록된 다수의 판매 채널에 동시 판매가 가능합니다. `}
          />
        </Mobile>
        <PC>
          <button onClick={() => window.open("https://mallpie.kr/store")}>
            몰파이 입점 안내
            <img src={commonArrowRight} alt="commonArrowRight" />
          </button>
        </PC>
      </motion.div>
      <div css={container}>
        <div className="box">
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="img">
              <img src={icon1} alt="icon1" />
            </div>
            <div className="text">
              <div className="title">한 번의 입점으로 다수 고객에게 판매</div>
              <div className="desc">
                개인, 크리에이터, 기업 등<br />
                평균 100여개 이상의 채널에서 판매될 수 있습니다.
              </div>
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="img">
              <img src={icon2} alt="icon2" />
            </div>
            <div className="text">
              <div className="title">카테고리별 전문 MD 매칭</div>
              <div className="desc">
                입점 완료 시, 카테고리별 전문 MD가 1:1로 매칭되어 <br />
                상품 노출 기회를 확대하고 매출 증대를 누릴 수 있습니다.
              </div>
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="img">
              <img src={icon3} alt="icon3" />
            </div>
            <div className="text">
              <div className="title">외부에 검색되지 않는 폐쇄적 운영</div>
              <div className="desc">
                몰파이는 검색 사이트 등에 노출되지 않는 폐쇄몰 채널로,{" "}
                <Mobile>
                  <br />
                </Mobile>
                회원 한정 가격으로 판매 경쟁력을 확보할 수 있습니다.
              </div>
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="img">
              <img src={icon4} alt="icon4" />
            </div>
            <div className="text">
              <div className="title">데이터 분석을 통한 맞춤 상품 추천</div>
              <div className="desc">
                고객 구매 패턴 및 상품 선호를 분석하여 최적의 채널에서
                <Mobile>
                  <br />
                </Mobile>
                판매 될 수 있는 시스템을 갖추고 있습니다.
              </div>
            </div>
          </motion.div>
          <Mobile>
            <button onClick={() => window.open("https://mallpie.kr/store")}>
              몰파이 입점 안내
              <img src={commonArrowRight} alt="commonArrowRight" />
            </button>
          </Mobile>
        </div>
      </div>
    </div>
  );
};

export default Section6;
