/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowUp from "static/common/arrow_up.svg";
import arrowDown from "static/common/arrow_down.png";

interface Children {
  name: React.ReactNode;
  path: string;
}

interface MenuItemProps {
  name: string;
  path?: string;
  children?: Children[];
  menuOff: () => void;
}

const MenuItem = ({ name, path, children, menuOff }: MenuItemProps) => {
  const menuItemStyle = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    flex-wrap: wrap;
    &.active {
      background-color: #fff;
      color: #000;
    }
    img {
      width: 16px;
    }
    ul {
      width: 100%;
      margin-top: 25px;
    }
    ul > li {
      padding: 13px 60px;
      display: flex;
      align-items: center;
      background: #fff;
      color: rgba(0, 0, 0, 0.5);
      font-size: 16px;
      font-weight: 500;
      gap: 4px;
      line-height: 24px;
    }
  `;

  const navigater = useNavigate();
  const [toggle, setToggle] = useState(false);

  return (
    <div
      css={menuItemStyle}
      className={toggle ? "active" : ""}
      onClick={() => {
        if (children) setToggle((prev) => !prev);
        if (!children && path) navigater(path);
      }}
    >
      {name}
      {children && (
        <>
          <img src={toggle ? arrowUp : arrowDown} alt="arrow" />
          {toggle && (
            <ul>
              {children.map((v, k) => (
                <li
                  onClick={() => {
                    navigater(v.path);
                    menuOff();
                  }}
                  key={k}
                >
                  {v.name}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
};

export default MenuItem;
