/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import SectionTitle from "components/Common/SectionTitle";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import list1Img from "static/mallpieBiz/list1.png";
import list2Img from "static/mallpieBiz/list2.png";
import list3Img from "static/mallpieBiz/list3.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section2 = () => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const section2Style = css`
    padding: 120px 0;
    background: rgba(79, 128, 255, 0.1);
    .box {
      display: flex;
      gap: 20px;
      flex-direction: column;
      margin-top: 80px;
      .list {
        padding: 0 92px;
        height: 480px;
        display: flex;
        align-items: center;
        background: #fff;
        justify-content: space-between;
        border-radius: 24px;
        .img {
          width: 483px;
          img {
            width: 100%;
          }
        }
        .text {
          .title {
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 48px;
          }
          .desc {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: -0.18px;
            opacity: 0.6;
            margin-top: 20px;
          }
        }
      }

      .list:nth-of-type(2) {
        flex-direction: row-reverse;
        justify-content: start;
        .img {
          width: 360px;
        }
        .text {
          margin-left: 140px;
        }
      }
      .list:nth-of-type(3) {
        padding-right: 20px;
        .img {
          width: 526px;
        }
      }
    }
    ${tabandmo} {
      padding: 75px 0px;
      background: #fff;
      .box {
        flex-direction: column;
        gap: 90px;
        .list {
          flex-direction: column-reverse;
          align-items: flex-start;
          padding: 0 20px;
          height: auto;
          .img {
            width: 100%;
            padding: 0 24px;
          }
          .text {
            margin-left: 0;
            margin-top: 20px;
            .title {
              font-size: 22px;
              font-style: normal;
              font-weight: 700;
              line-height: 28.93px;
            }
            .desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24.155px;
              margin-top: 15px;
            }
          }
        }
        .list:nth-of-type(2) {
          flex-direction: column-reverse;
          .img {
            width: 100%;
          }
          .text {
            margin-left: 0;
          }
        }
        .list:nth-of-type(3) {
          .img {
            width: 100%;
          }
        }
      }
    }
  `;

  return (
    <div css={section2Style}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.2 }}
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        <SectionTitle
          mainText={
            isMobile
              ? `천만 고객을 보유한 기업이\n선택한 커머스 플랫폼\n몰파이`
              : `천만 고객을 보유한 기업이\n선택한 커머스 플랫폼 몰파이`
          }
        />
      </motion.div>
      <div css={container}>
        <div className="box">
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="text">
              <div className="title">
                국내 유일의 상품 결합형
                <br />
                쇼핑 플랫폼, 몰파이
              </div>
              <div className="desc">
                커머스의 핵심은 결국 상품. <br />
                몰파이는 커머스 시스템과 10만여 개의 <br />
                온라인 최저가 상품이 결합된 국내 유일의
                <br />
                상품 결합형 쇼핑 플랫폼입니다.
              </div>
            </div>
            <div className="img">
              <img src={list1Img} alt="list1" />
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="text">
              <div className="title">
                구축부터 운영까지
                <br />
                올인원 커머스 솔루션
              </div>
              <div className="desc">
                쇼핑몰 개발, 경쟁력을 갖춘 상품, 운영 및 CS까지.
                <br />
                쇼핑몰의 도입부터 운영까지 모두 준비되어 있습니다.
                <br />
                몰파이 하나로 커머스 전문 역량 내재화가 가능합니다.
              </div>
            </div>
            <div className="img">
              <img src={list2Img} alt="list1" />
            </div>
          </motion.div>
          <motion.div
            className="list"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <div className="text">
              <div className="title">
                내부 관리자, 외부 고객
                <br />
                모두가 만족하는 사용성
              </div>
              <div className="desc">
                내부 관리자를 위한 데이터 관리 시스템,
                <br />
                쇼핑몰 이용 고객을 위한 디테일한 커머스 기능,
                <br />
                모두가 만족하는 커머스 사용성을 갖추고 있습니다.
              </div>
            </div>
            <div className="img">
              <img src={list3Img} alt="list1" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
export default Section2;
